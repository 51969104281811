import styled from "styled-components";

const Button = styled.button`
  background-color: #008099;
  height: 38px;
  width: 170px;
  border-radius: 10px;
  color: white;
  font-size: 16px;
  font-weight: 700;
  border: 0;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export default Button;
