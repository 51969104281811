import React from "react";
import Button from "./Button";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

interface GetInTouchButtonProps {
  width?: number;
  height?: number;
  boxShadow?: string;
  color?: string;
  backgroundColor?: string;
  fontSize?: string;
}

const GetInTouchButton: React.FC<GetInTouchButtonProps> = ({
  width,
  height,
  boxShadow,
  color,
  backgroundColor,
  fontSize,
}) => {
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const defaultWidth = isMobile ? "90%" : "710px";
  return (
    <Link
      to="/contacts"
      onClick={() => window.scrollTo(0, 0)}
      style={{ width: isMobile ? "100%" : undefined }}
    >
      <Button
        style={{
          height: height ? height : "52px",
          width: width ? width : defaultWidth,
          boxShadow: boxShadow ? boxShadow : "5px 30px 30px rgba(0, 0, 0, 0.2)",
          color,
          backgroundColor,
          fontSize,
        }}
      >
        Get in Touch
      </Button>
    </Link>
  );
};

export default GetInTouchButton;
