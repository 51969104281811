import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import {
  Container,
  MobileContainer,
  NavItem,
  Spacer,
  EmployeeAreaButton,
  HamburgerMenuItem,
  CloseButton,
  CloseButtonContainer,
  EmployeeAreaMobileContainer,
  HeightSpacer,
  GetInTouchMobileButtonContainer,
} from "./styles/Header.styled";
import Logo from "../static/icons/Logo Top.svg";
import GetInTouchButton from "./GetInTouchButton";

const LOGIN_PAGE_URL = "https://portal.sentosashipbrokers.com";

const DesktopHeader = () => {
  const location = useLocation();
  return (
    <Container>
      <Link to="/">
        <img
          alt="Sentosa Shipbrokers logo"
          src={Logo}
          height={30}
          width={124}
          style={{ paddingRight: "30px" }}
        />
      </Link>
      <Link to="/about" style={{ height: "100%" }}>
        <NavItem isActive={location.pathname === "/about"}>
          <span>About Us</span>
        </NavItem>
      </Link>
      <Link to="/careers" style={{ height: "100%" }}>
        <NavItem isActive={location.pathname === "/careers"}>
          <span>Careers</span>
        </NavItem>
      </Link>
      <Link to="/find-us" style={{ height: "100%" }}>
        <NavItem isActive={location.pathname === "/find-us"}>
          <span>Find Us</span>
        </NavItem>
      </Link>
      <Link to="/departments" style={{ height: "100%" }}>
        <NavItem isActive={location.pathname === "/departments"}>
          <span>Departments</span>
        </NavItem>
      </Link>
      <Link to="/contacts" style={{ height: "100%" }}>
        <NavItem isActive={location.pathname === "/contacts"}>
          <span>Contacts</span>
        </NavItem>
      </Link>
      <Link to="/csr" style={{ height: "100%" }}>
        <NavItem isActive={location.pathname === "/csr"}>
          <span>CSR</span>
        </NavItem>
      </Link>
      <Spacer />
      <Link to={LOGIN_PAGE_URL} style={{ height: "100%" }}>
        <EmployeeAreaButton>Employee Area</EmployeeAreaButton>
      </Link>
      <GetInTouchButton boxShadow="0" width={170} height={38} />
    </Container>
  );
};

const MobileHeader = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsSidebarOpen(false);
  }, [location]);

  return (
    <MobileContainer>
      <Link to="/">
        <img
          alt="Sentosa Shipbrokers logo"
          src={Logo}
          height={30}
          width={124}
          style={{ paddingRight: "30px" }}
        />
      </Link>
      <GetInTouchMobileButtonContainer>
        <GetInTouchButton
          boxShadow="0"
          width={120}
          height={55}
          color="#008099"
          backgroundColor="white"
          fontSize="14px"
        />
      </GetInTouchMobileButtonContainer>
      <button onClick={() => setIsSidebarOpen(true)}>
        <GiHamburgerMenu color="white" size={20} />
      </button>
      <div>
        <Menu
          onStateChange={(state) => setIsSidebarOpen(state.isOpen)}
          right
          customBurgerIcon={false}
          customCrossIcon={false}
          isOpen={isSidebarOpen}
          styles={{
            bmMenu: { backgroundColor: "#008099" },
            bmBurgerButton: { width: "0", height: "0", zIndex: "-1" },
            bmCrossButton: { width: "0" },
          }}
        >
          <CloseButtonContainer>
            <CloseButton onClick={() => setIsSidebarOpen(false)}>
              <IoMdClose color="white" size={24} />
            </CloseButton>
          </CloseButtonContainer>
          <Link to="/about">
            <HamburgerMenuItem isActive={location.pathname === "/about"}>
              <span>About Us</span>
            </HamburgerMenuItem>
          </Link>
          <Link to="/careers">
            <HamburgerMenuItem isActive={location.pathname === "/careers"}>
              <span>Careers</span>
            </HamburgerMenuItem>
          </Link>
          <Link to="/find-us">
            <HamburgerMenuItem isActive={location.pathname === "/find-us"}>
              <span>Find Us</span>
            </HamburgerMenuItem>
          </Link>
          <Link to="/departments">
            <HamburgerMenuItem isActive={location.pathname === "/departments"}>
              <span>Departments</span>
            </HamburgerMenuItem>
          </Link>
          <Link to="/contacts">
            <HamburgerMenuItem isActive={location.pathname === "/contacts"}>
              <span>Contacts</span>
            </HamburgerMenuItem>
          </Link>
          <Link to="/csr">
            <HamburgerMenuItem isActive={location.pathname === "/csr"}>
              <span>CSR</span>
            </HamburgerMenuItem>
          </Link>
          <HeightSpacer />

          <EmployeeAreaMobileContainer>
            <Link to={LOGIN_PAGE_URL}>Employee Area</Link>
          </EmployeeAreaMobileContainer>
        </Menu>
      </div>
    </MobileContainer>
  );
};

const Header = () => {
  const shouldUseHamburger = useMediaQuery({
    query: "(max-width: 1255px)",
  });
  if (shouldUseHamburger) {
    return <MobileHeader />;
  } else {
    return <DesktopHeader />;
  }
};

export default Header;
