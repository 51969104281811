import React from "react";
import {
  Input,
  Container,
  LeftIcon,
  RightIcon,
} from "./styles/SearchBar.styled";
import Clear from "../static/icons/Clear.svg";

interface SearchBarProps {
  onSearchQueryChanged: (searchTerm: string) => void;
  placeholder?: string;
  value: string;
  leftIcon?: string;
  onLeftIconClicked?: () => void;
  containerStyles?: React.CSSProperties;
  ref?: React.RefObject<HTMLInputElement>;
}

const SearchBar: React.FC<SearchBarProps> = ({
  onSearchQueryChanged,
  placeholder,
  value,
  leftIcon,
  onLeftIconClicked,
  containerStyles,
}) => {
  return (
    <Container style={containerStyles}>
      <Input
        placeholder={placeholder}
        value={value}
        onChange={(e) => onSearchQueryChanged(e.target.value)}
      />
      {leftIcon ? (
        <LeftIcon src={leftIcon} onClick={onLeftIconClicked} />
      ) : null}
      <RightIcon src={Clear} onClick={() => onSearchQueryChanged("")} />
    </Container>
  );
};

export default SearchBar;
