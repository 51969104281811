import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PointerDown from "../static/icons/Pointer Down.svg";
import IconButton from "../components/IconButton";
import {
  Container,
  FirstPanel,
  AboutPanel,
  AboutLeftPanel,
  AboutRightPanel,
} from "./styles/About.styled";
import CallToAction from "../components/CallToAction";

const About = () => {
  const onScrollButtonClicked = () => {
    const element = document.getElementById("aboutpanel");
    element && element.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Container>
      <Header />
      <FirstPanel>
        <h1>About Us</h1>
        <h4>
          Founded in 2018, Sentosa Ship Brokers is an independent ship broking
          house with unparalleled global experience that focuses on the Clean
          Petroleum Products, Dirty Petroleum Products, Crude Oil, Coastal,
          Projects and LPG tanker markets.
          <br />
        </h4>
        <IconButton icon={PointerDown} onClick={onScrollButtonClicked} />
      </FirstPanel>
      <AboutPanel id="aboutpanel">
        <AboutLeftPanel>
          <div>
            <h4>
              With offices in Singapore, Dubai, New Delhi and Geneva and
              employees from Singapore, China, Malaysia, United Kingdom, Japan,
              Mexico, Denmark, India, Greece and Switzerland, Sentosa Ship
              Brokers offers an extensive insight into wet and gas shipping
              markets across the globe.
            </h4>
            <br />
            <p>
              Sentosa Ship Brokers provides a full service offering to its
              clients that consist of oil majors, traders and ship owners. From
              precise and up to the minute knowledge of the spot and period
              markets, we successfully facilitate negotiations for our clients
              with a strong emphasis in ensuring both sides receive the best
              possible intelligence to assist their decision-making process.
              Offering twenty-four-hour assistance, Sentosa Ship Brokers
              provides a professional and experienced team to guide its clients
              from pre-fixture through to loading, discharge operations and
              claim negotiations.
            </p>
          </div>
        </AboutLeftPanel>
        <AboutRightPanel>
          <h3>offering</h3>
          <h1>24/7</h1>
          <h3>assistance</h3>
          <br />
          <br />
          <h3>offices in</h3>
          <h1>3</h1>
          <h3>continents</h3>
        </AboutRightPanel>
      </AboutPanel>
      <CallToAction text="Speak to one of our specialists" />
      <Footer />
    </Container>
  );
};

export default About;
