import styled from "styled-components";
import CareerPanelBackground from "../../static/images/Careers.jpg";
import CareerPanelMobileBackground from "../../static/images/mobile/Careers.jpg";

export const Container = styled.div``;

export const FirstPanel = styled.div`
  width: 100%;
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${CareerPanelBackground}) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding-bottom: 70px;

  @media (max-width: 768px) {
    background-image: url(${CareerPanelMobileBackground}) !important;
    padding-bottom: 110px;
  }

  > h1 {
    margin-top: 67px;

    @media (max-width: 768px) {
      margin-top: 110px;
    }
  }

  > h4 {
    margin: 45px 16%;

    @media (max-width: 768px) {
      margin-right: 20px;
      margin-left: 20px;
    }
  }
`;

export const PositionContainer = styled.div`
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 93px;
  padding-bottom: 93px;
  width: 100%;
  text-align: center;

  h2 {
    margin-bottom: 41px;
  }

  @media (max-width: 900px) {
    background-color: white;
    padding-top: 60px;
    padding-bottom: 0px;
  }
`;

export const PositionList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;

  > div {
    height: 60px;
    @media (max-width: 900px) {
      height: auto;
    }
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }
`;

export const PositionName = styled.div`
  font-size: 22px;
  font-weight: 900;
  display: flex;
  align-items: center;
  height: 60px;

  @media (max-width: 900px) {
    font-size: 18px;
  }
`;

export const PositionLocations = styled.div`
  font-size: 22px;
  font-weight: 400;
  color: #152941;
  display: flex;
  align-items: center;
  height: 60px;

  @media (max-width: 900px) {
    margin: 10px 0 50px 0;
    font-size: 18px;
  }
`;
