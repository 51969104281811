import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Header from "../components/Header";
import Footer from "../components/Footer";
import IconButton from "../components/IconButton";
import CallToAction from "../components/CallToAction";
import {
  AboutLeftPanel,
  AboutRightPanel,
  AboutPanel,
  Container,
  FirstPanel,
  ServicesPanel,
  ServicesLeftContent,
  ServicesRightContent,
  ServicesBottomContent,
  LocationsPanel,
  ReadMoreContainer,
} from "./styles/Home.styled";
import PointerDown from "../static/icons/Pointer Down.svg";
import ArrowRight from "../static/icons/Arrow Right.svg";
import ArrowRightLight from "../static/icons/ArrowRight-Light.svg";
import LocationImage from "../static/images/Homepage-Locations.png";
import LocationsMobileImage from "../static/images/mobile/MapMobile1x.png";

const Home = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const isSinglePanelMode = useMediaQuery({
    query: "(max-width: 1300px)",
  });
  const onScrollButtonClicked = () => {
    const element = document.getElementById("aboutuspanel");
    element && element.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Container>
      <Header />
      <FirstPanel>
        <h1>GLOBAL TANKER CHARTERING SPECIALISTS</h1>
        <h4>
          Founded in Singapore in 2018, Sentosa Ship Brokers focuses on the
          clean, dirty, gas, coastal and project tanker markets.
        </h4>
        <IconButton icon={PointerDown} onClick={onScrollButtonClicked} />
      </FirstPanel>
      <AboutPanel id="aboutuspanel">
        <AboutLeftPanel>
          <div>
            <h3>About Us</h3>
            <h2>
              We are an independent ship broking house with unparalleled global
              experience
            </h2>
            <p>
              With employees from Singapore, China, United Kingdom, Japan,
              Malaysia, Mexico, Denmark, India, Greece, Italy and
              Switzerland. Sentosa Ship Brokers offer an extensive insight into
              tanker markets across the globe.
            </p>
            <Link to="/about">
              <ReadMoreContainer>
                <IconButton icon={ArrowRight} />
                <h4>Read more</h4>
              </ReadMoreContainer>
            </Link>
          </div>
        </AboutLeftPanel>
        <AboutRightPanel>
          <h3>offering</h3>
          <h1>24/7</h1>
          <h3>assistance</h3>
          <br />
          <br />
          <h3>offices in</h3>
          <h1>3</h1>
          <h3>continents</h3>
        </AboutRightPanel>
      </AboutPanel>
      <ServicesPanel>
        {!isSinglePanelMode ? (
          <ServicesLeftContent>
            <h2>
              Spot & <br />
              Time-Charter
            </h2>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <h2>
              Market <br />
              Intelligence
            </h2>
          </ServicesLeftContent>
        ) : null}
        <ServicesRightContent>
          <h3>Services</h3>
          <h2>
            Sentosa Ship Brokers provides a full service offering to its clients
            that consist of oil majors, traders and ship owners.
          </h2>
          <p>
            From precise and up to the minute knowledge of the spot and time
            charter markets, we successfully facilitate negotiations for our
            clients with a strong emphasis in ensuring both sides receive the
            best possible intelligence to assist their decision making process.
            Offering 24/7/365 assistance, Sentosa Ship Brokers provides a
            professional and experienced team to guide its clients from
            pre-fixture through to loading, discharge operations and claim
            negotiation.
          </p>
          <Link to="/about">
            <ReadMoreContainer>
              <IconButton icon={ArrowRightLight} />
              <h4>Read more</h4>
            </ReadMoreContainer>
          </Link>
          {isSinglePanelMode ? (
            <ServicesBottomContent>
              <h2>
                Spot & <br />
                Time-Charter
              </h2>
              <h2>
                Market <br />
                Intelligence
              </h2>
            </ServicesBottomContent>
          ) : null}
        </ServicesRightContent>
      </ServicesPanel>
      <LocationsPanel>
        <h2>Our Locations</h2>
        <img
          src={isMobile ? LocationsMobileImage : LocationImage}
          alt="Our locations"
        />
      </LocationsPanel>
      <CallToAction text="Speak to one of our specialists" />
      <Footer />
    </Container>
  );
};

export default Home;
