import styled from "styled-components";
import DepartmentBackground from "../../static/images/Departments.jpg";
import DepartmentMobileBackground from "../../static/images/mobile/Departments.jpg";

export const Container = styled.div``;

export const FirstPanel = styled.div`
  width: calc(100% - 140px);
  color: white;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(${DepartmentBackground}) !important;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 22px;
  padding-left: 140px;
  padding-bottom: 200px;
  height: 1000px;

  @media (max-width: 768px) {
    background-image: url(${DepartmentMobileBackground}) !important;
    padding-left: 20px;
    width: calc(100% - 20px);
    height: 720px;
  }

  > h1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 67px;
    margin-bottom: 90px;

    @media (max-width: 768px) {
      margin-top: 40px;
      margin-bottom: 20px;
      font-size: 35px;
    }
  }
`;

export const SummaryText = styled.span`
  margin-left: 10px;
  font-size: 30px;
  font-weight: 900;
  display: flex;
  cursor: pointer;

  > img {
    margin-right: 20px;
  }
`;

export const ExpandableItem = styled.div`
  margin: 14px;
`;

export const NonExpandableItem = styled.span`
  margin: 14px;
  margin-left: 64px;
  font-size: 30px;
  font-weight: 900;
`;

export const Label = styled.div``;

export const HiddenText = styled.div<{ visible?: boolean }>`
  margin-left: 60px;
  margin-top: 26px;
  margin-bottom: 40px;
  display: ${({ visible }) => (visible ? "block" : "none")};

  > div {
    margin: 10px;
  }
`;
