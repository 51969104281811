import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PointerDown from "../static/icons/Pointer Down.svg";
import CallToAction from "../components/CallToAction";
import IconButton from "../components/IconButton";
import {
  Container,
  FirstPanel,
  PositionContainer,
  PositionList,
  PositionName,
  PositionLocations,
} from "./styles/Careers.styled";
import PinImage from "../static/icons/Pin.svg";

const Pin = () => {
  return <img src={PinImage} alt="Location" style={{ marginRight: "12px" }} />;
};

const Careers = () => {
  const onScrollButtonClicked = () => {
    const element = document.getElementById("positioncontainer");
    element && element.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Container>
      <Header />
      <FirstPanel>
        <h1>Careers</h1>
        <h4>
          We are looking to expand our tanker broking and operations desks and
          would be happy to hear from you, whether you’re an industry veteran or
          a graduate with the ambition and drive it takes to succeed in a high
          paced and challenging environment.
        </h4>
        <IconButton icon={PointerDown} onClick={onScrollButtonClicked} />
      </FirstPanel>
      <PositionContainer id="positioncontainer">
        <h2>Current Positions</h2>
        <PositionList>
          <PositionName>Experienced LPG Tanker Brokers</PositionName>
          <PositionLocations>
            <Pin />
            Singapore, India, Dubai and Switzerland
          </PositionLocations>
          <PositionName>Experienced LNG Tanker Brokers</PositionName>
          <PositionLocations>
            <Pin />
            Singapore, India, Dubai and Switzerland
          </PositionLocations>
          <PositionName>Experienced Projects and T/C brokers</PositionName>
          <PositionLocations>
            <Pin />
            Singapore and Switzerland
          </PositionLocations>
          <PositionName>Experienced DPP Tanker Broker</PositionName>
          <PositionLocations>
            <Pin />
            Singapore, Dubai and Switzerland
          </PositionLocations>
          <PositionName>Experienced CPP Tanker Broker</PositionName>
          <PositionLocations>
            <Pin />
            Singapore, Dubai and Switzerland
          </PositionLocations>
          <PositionName>Tanker Operations</PositionName>
          <PositionLocations>
            <Pin />
            Dubai
          </PositionLocations>
          <PositionName>Claims Executive</PositionName>
          <PositionLocations>
            <Pin />
            Dubai
          </PositionLocations>
          <PositionName>Office Administration</PositionName>
          <PositionLocations>
            <Pin />
            Dubai
          </PositionLocations>
        </PositionList>
      </PositionContainer>
      <CallToAction text="Contact us for more information" />
      <Footer />
    </Container>
  );
};

export default Careers;
