import styled from "styled-components";
import CSRBackground from "../../static/images/CSR.jpg";
import CSRMobileBackground from "../../static/images/mobile/CSR.jpg";

export const Container = styled.div``;

export const FirstPanel = styled.div`
  width: 100%;
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${CSRBackground}) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding-top: 127px;
  padding-bottom: 127px;

  @media (max-width: 768px) {
    padding-top: 40px;
    background-image: url(${CSRMobileBackground}) !important;
    padding-bottom: 20px;
  }

  > h2 {
    @media (max-width: 768px) {
      line-height: 60px;
    }
  }

  > h4 {
    margin: 20px 0;
    font-weight: 400;

    @media (max-width: 768px) {
      margin-top: 40px;
      font-size: 22px;
      padding: 0 30px;
    }
  }
`;

export const Company = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }

  > p {
    margin-top: 20px;

    @media (max-width: 768px) {
      margin-top: 6px;
      margin-bottom: 40px;
    }
  }
`;

export const CompanyContainer = styled.div`
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 30px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;
