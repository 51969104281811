import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const LeftIcon = styled.img`
  position: absolute;
  left: 20px;
  cursor: pointer;
`;

export const RightIcon = styled.img`
  position: absolute;
  right: 20px;
  cursor: pointer;
`;

export const Input = styled.input<{ value: string }>`
  border-radius: 10px;
  border-width: 0px;
  opacity: 1;
  background-color: ${({ value }) =>
    value ? "white" : "rgba(236, 236, 236, 1)"};
  height: 52px;
  width: 100%;
  color: #3e5058;
  font-size: 22px;
  line-height: 23px;
  font-weight: 400;
  padding-left: 60px;
  padding-right: 50px;
  font-family: "Lato", sans-serif;
  outline: none;
`;
