import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useLocation } from "react-router-dom";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CallToAction from "../components/CallToAction";
import {
  Container,
  FirstPanel,
  TableContainer,
  Label,
  ContactsGrid,
  ContactInnerContainer,
  TocItem,
  LeftPanel,
  RightPanel,
  MobilePanel,
  Detail,
  Contact,
  InnerDetail,
  InnerDetailMobile,
  BackToDepartmentListButton,
  DepartmentHeader,
  DetailMobile,
} from "./styles/Contacts.styled";
import ChevronUp from "../static/icons/ChevronUp.svg";
import ChevronDown from "../static/icons/ChevronDown.svg";
import SearchBar from "../components/SearchBar";
import BackArrow from "../static/icons/BackArrow.svg";
import MagnifyingGlass from "../static/icons/MagnifyingGlass.svg";
import contactsJson from "../data/contacts.json";

interface Person {
  name: string;
  title?: string;
  email?: string;
  desk_email?: string;
  desk?: string;
  direct?: string;
  mobile?: string;
  office: string;
}
interface Department {
  department: string;
  departmentId: string;
  people: Person[];
}

const data: Department[] = contactsJson;

interface ContactContainerProps {
  person: Person;
  isSelected: boolean;
  onClick: (person: Person) => void;
}

const MobileContactContainer: React.FC<ContactContainerProps> = ({
  person,
  isSelected,
  onClick,
}) => {
  return (
    <Contact>
      <ContactInnerContainer
        isOpen={isSelected}
        onClick={() => onClick(person)}
        style={{
          backgroundColor: isSelected ? "white" : undefined,
        }}
      >
        <Label bold={isSelected}>
          {isSelected ? (
            <img src={ChevronUp} alt="Up" />
          ) : (
            <img src={ChevronDown} alt="Down" />
          )}
          {person.name}
        </Label>
      </ContactInnerContainer>
      {isSelected && (
        <DetailMobile isOpen={isSelected}>
          <InnerDetailMobile>
            <h4 style={{ fontWeight: 400 }}>OFFICE</h4>
            <h4>
              <a href={`mailto:${person.email}`}>{person.office}</a>
            </h4>
            <br />
            <h4 style={{ fontWeight: 400 }}>EMAIL</h4>
            <h4>
              <a href={`mailto:${person.email}`}>{person.email}</a>
            </h4>
            <br />
            <h4 style={{ fontWeight: 400 }}>DESK EMAIL</h4>
            <h4>
              <a href={`mailto:${person.desk_email}`}>{person.desk_email}</a>
            </h4>
            <br />
            <h4 style={{ fontWeight: 400 }}>PHONE</h4>
            <h4>
              <a href={`tel:${person.mobile}`}>{person.mobile}</a>
            </h4>
          </InnerDetailMobile>
        </DetailMobile>
      )}
    </Contact>
  );
};

const DesktopContactContainer: React.FC<ContactContainerProps> = ({
  person,
  isSelected,
  onClick,
}) => {
  return (
    <Contact>
      <ContactsGrid
        onClick={() => onClick(person)}
        style={{
          backgroundColor: isSelected ? "white" : undefined,
          marginLeft: "40px",
        }}
      >
        <Label bold={isSelected}>
          {isSelected ? (
            <img src={ChevronUp} alt="Up" />
          ) : (
            <img src={ChevronDown} alt="Down" />
          )}
          {person.name}
        </Label>
        <Label bold={isSelected}>{person.office}</Label>
      </ContactsGrid>
      {isSelected && (
        <Detail>
          <InnerDetail>
            <h4 style={{ fontWeight: 400 }}>EMAIL</h4>
            <h4>
              <a href={`mailto:${person.email}`}>{person.email}</a>
            </h4>
            <h4 style={{ fontWeight: 400 }}>DESK EMAIL</h4>
            <h4>
              <a href={`mailto:${person.desk_email}`}>{person.desk_email}</a>
            </h4>
            <h4 style={{ fontWeight: 400 }}>PHONE</h4>
            <h4>
              <a href={`tel:${person.mobile}`}>{person.mobile}</a>
            </h4>
          </InnerDetail>
        </Detail>
      )}
    </Contact>
  );
};

interface FirstPanelContentProps {
  selectedDepartment?: string;
  setSelectedDepartment: (department: string | undefined) => void;
  filterQuery: string;
  leftPanelItems: string[];
  onFilterQueryChanged: (query: string) => void;
  people: Person[];
  onPersonClicked: (person: Person) => void;
  selectedPeople: Set<string>;
  setFilterQuery: (query: string) => void;
}

const DesktopFirstPanelContent: React.FC<FirstPanelContentProps> = ({
  selectedDepartment,
  setSelectedDepartment,
  filterQuery,
  leftPanelItems,
  onFilterQueryChanged,
  people,
  onPersonClicked,
  selectedPeople,
}) => {
  return (
    <>
      <h1>Contacts</h1>
      <TableContainer>
        <LeftPanel>
          <Label bold>Department</Label>
          {leftPanelItems.map((name) => {
            const departmentId = data.find(
              (dep) => dep.department === name
            )?.departmentId;
            return (
              <TocItem
                onClick={() => {
                  if (name === "All") {
                    setSelectedDepartment("All");
                  } else {
                    setSelectedDepartment(departmentId);
                  }
                }}
              >
                <Label
                  bold={
                    (selectedDepartment &&
                      selectedDepartment === departmentId) ||
                    (selectedDepartment === "All" && name === "All")
                  }
                >
                  {name}
                </Label>
              </TocItem>
            );
          })}
        </LeftPanel>
        <RightPanel>
          <SearchBar
            containerStyles={{ margin: "0 26% 40px 40px" }}
            value={filterQuery}
            placeholder="Find by name or office"
            leftIcon={
              selectedDepartment === "All" ? MagnifyingGlass : BackArrow
            }
            onLeftIconClicked={
              selectedDepartment === "All"
                ? undefined
                : () => setSelectedDepartment("All")
            }
            onSearchQueryChanged={onFilterQueryChanged}
          />
          <ContactsGrid>
            <Label bold>Name</Label>
            <Label bold>Office</Label>
          </ContactsGrid>
          {people?.map((person) => {
            const isSelected = selectedPeople.has(person.name);
            return (
              <DesktopContactContainer
                person={person}
                isSelected={isSelected}
                onClick={onPersonClicked}
              />
            );
          })}
        </RightPanel>
      </TableContainer>
    </>
  );
};

const MobileFirstPanelContent: React.FC<FirstPanelContentProps> = ({
  selectedDepartment,
  setSelectedDepartment,
  filterQuery,
  leftPanelItems,
  onFilterQueryChanged,
  people,
  onPersonClicked,
  selectedPeople,
  setFilterQuery,
}) => {
  return (
    <>
      <h1 style={{ fontSize: "35px" }}>Contacts</h1>
      <TableContainer>
        <MobilePanel>
          <DepartmentHeader>Department</DepartmentHeader>
          <SearchBar
            containerStyles={{ margin: "20px 25px 0px 25px" }}
            value={filterQuery}
            placeholder="Find by name or office"
            leftIcon={
              selectedDepartment === "All" || !selectedDepartment
                ? MagnifyingGlass
                : BackArrow
            }
            onLeftIconClicked={
              selectedDepartment === "All" || !selectedDepartment
                ? undefined
                : () => setSelectedDepartment("All")
            }
            onSearchQueryChanged={onFilterQueryChanged}
          />
          {selectedDepartment === undefined ? (
            <>
              {leftPanelItems.map((name: string) => {
                const departmentId = data.find(
                  (dep) => dep.department === name
                )?.departmentId;
                return (
                  <TocItem
                    onClick={() => {
                      if (name === "All") {
                        setSelectedDepartment("All");
                      } else {
                        setSelectedDepartment(departmentId);
                      }
                    }}
                  >
                    <Label
                      bold={
                        (selectedDepartment &&
                          selectedDepartment === departmentId) ||
                        (selectedDepartment === "All" && name === "All")
                      }
                    >
                      {name}
                    </Label>
                  </TocItem>
                );
              })}
            </>
          ) : (
            <>
              <BackToDepartmentListButton
                onClick={() => {
                  setSelectedDepartment(undefined);
                  setFilterQuery("");
                }}
              >
                <IoChevronBackCircleOutline />
                {selectedDepartment === "All"
                  ? "All"
                  : data.find((dep) => dep.departmentId === selectedDepartment)
                      ?.department}
              </BackToDepartmentListButton>

              {people?.map((person) => {
                const isSelected = selectedPeople.has(person.name);
                return (
                  <MobileContactContainer
                    person={person}
                    isSelected={isSelected}
                    onClick={onPersonClicked}
                  />
                );
              })}
            </>
          )}
        </MobilePanel>
      </TableContainer>
    </>
  );
};

const Contacts = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery({
    query: "(max-width: 1200px)",
  });
  let defaultDepartment = isMobile ? undefined : "All";
  const hash = location.hash.replace("#", "");
  if (hash && data.some((dep) => dep.departmentId === hash)) {
    defaultDepartment = hash;
  }
  const [selectedDepartment, setSelectedDepartment] = useState<
    string | undefined
  >(defaultDepartment);
  const [selectedPeople, setSelectedPeople] = useState<Set<string>>(new Set());
  const [filterQuery, setFilterQuery] = useState<string>("");

  useEffect(() => {
    setSelectedPeople(new Set());
  }, [selectedDepartment]);

  useEffect(() => {
    if (!isMobile && selectedDepartment === undefined) {
      setSelectedDepartment("All");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  useEffect(() => {
    if (!selectedDepartment) {
      navigate(location.pathname, { replace: true });
    } else if (selectedDepartment === "All") {
      navigate(`${location.pathname}#all`, { replace: true });
    } else {
      navigate(`${location.pathname}#${selectedDepartment}`, {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartment]);

  const leftPanelItems = [
    "All",
    ...data.map((department) => {
      return department.department;
    }),
  ];

  let people: Person[] = [];
  if (selectedDepartment === "All") {
    const names = new Set();
    ["Singapore", "Dubai", "Geneva", "India"].forEach((location) => {
      data.forEach((dep) => {
        dep.people.forEach((person) => {
          // Don't list people who are in multiple departments twice
          if (!names.has(person.name) && person.office === location) {
            people.push(person);
            names.add(person.name);
          }
        });
      });
    });
  } else if (selectedDepartment) {
    const dep = data.find((dep) => dep.departmentId === selectedDepartment);
    if (dep) {
      people = dep.people;
    }
  }

  // If there is text in the search bar, filter the people accordingly
  if (filterQuery) {
    people = people.filter((person) => {
      return (
        person.name.toLowerCase().includes(filterQuery.toLowerCase()) ||
        person.office.toLowerCase().includes(filterQuery.toLowerCase())
      );
    });
  }

  const onPersonClicked = (person: Person) => {
    if (selectedPeople.has(person.name)) {
      selectedPeople.delete(person.name);
    } else {
      selectedPeople.add(person.name);
    }
    setSelectedPeople(new Set(selectedPeople));
  };

  const onFilterQueryChanged = (queryString: string) => {
    if (!selectedDepartment) {
      setSelectedDepartment("All");
    }
    setFilterQuery(queryString);
  };

  return (
    <Container>
      <Header />
      <FirstPanel>
        {isMobile ? (
          <MobileFirstPanelContent
            selectedDepartment={selectedDepartment}
            setSelectedDepartment={setSelectedDepartment}
            filterQuery={filterQuery}
            leftPanelItems={leftPanelItems}
            onFilterQueryChanged={onFilterQueryChanged}
            people={people}
            onPersonClicked={onPersonClicked}
            selectedPeople={selectedPeople}
            setFilterQuery={setFilterQuery}
          />
        ) : (
          <DesktopFirstPanelContent
            selectedDepartment={selectedDepartment}
            setSelectedDepartment={setSelectedDepartment}
            filterQuery={filterQuery}
            leftPanelItems={leftPanelItems}
            onFilterQueryChanged={onFilterQueryChanged}
            people={people}
            onPersonClicked={onPersonClicked}
            selectedPeople={selectedPeople}
            setFilterQuery={setFilterQuery}
          />
        )}
      </FirstPanel>
      <CallToAction text="Speak to one of our specialists" />
      <Footer />
    </Container>
  );
};

export default Contacts;
