import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 93px;
  align-items: center;
  width: calc(100% - 248px);
  justify-content: center;
  margin: 0 124px;
`;

export const MobileContainer = styled.div`
  height: 55px;

  img {
    margin: 13px;
    position: absolute;
  }

  > button {
    height: 55px;
    width: 55px;
    border: none;
    cursor: pointer;
    right: 0;
    background-color: #008099;
    position: absolute;
  }
`;

export const GetInTouchMobileButtonContainer = styled.div`
  position: absolute;
  right: 50px;
  margin-right: 14px;
`;

export const NavItem = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  height: ${({ isActive }) => (isActive ? "calc(100% - 6px)" : "100%")};
  padding: 0 14px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: ${({ isActive }) => (isActive ? 700 : 400)};
  color: #152941;
  border-bottom: ${({ isActive }) => (isActive ? "6px solid #008099" : "none")};
  background-color: ${({ isActive }) => (isActive ? "#F7F7F7" : "white")};

  > span {
    margin-top: ${({ isActive }) => (isActive ? "6px" : "0")};
  }
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;

export const HeightSpacer = styled.div`
  height: 100px;
`;

export const EmployeeAreaButton = styled.div`
  cursor: pointer;
  font-size: 16px;
  padding: 0 14px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export const HamburgerMenuItem = styled.a<{ isActive?: boolean }>`
  color: white;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  padding-left: 60px;
  height: 40px;
  background-color: ${({ isActive }) => (isActive ? "#023d4b" : "#06819a")};
`;

export const CloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 50px;
  width: 50px;
  margin-left: auto;
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  align-items: sp;
  width: 100%;
  height: 70px;
  background-color: #06819a;
  text-align: right;
`;

export const EmployeeAreaMobileContainer = styled.div`
  position: absolute;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  padding-left: 60px;
  padding-top: 10px;
  bottom: 0;
  color: white;
  font-size: 20px;
  font-weight: 400;
  vertical-align: center;

  > div {
    height: 40px;
  }
`;
