import React, { SyntheticEvent, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CallToAction from "../components/CallToAction";
import {
  Container,
  FirstPanel,
  SummaryText,
  HiddenText,
  NonExpandableItem,
  Label,
  ExpandableItem,
} from "./styles/Departments.styled";
import ChevronUp from "../static/icons/ChevronUp.svg";
import ChevronDown from "../static/icons/ChevronDown.svg";

const ExpandableIcon: React.FC<{ isExpanded: boolean }> = ({ isExpanded }) => {
  if (isExpanded) {
    return <img src={ChevronUp} alt="up" />;
  } else {
    return <img src={ChevronDown} alt="down" />;
  }
};

const Departments = () => {
  const [openDetails, setOpenDetails] = useState<string | undefined>();

  const onToggle = (e: SyntheticEvent<HTMLElement>, toggledField: string) => {
    e.preventDefault();
    setOpenDetails((oldState: string | undefined) => {
      if (oldState !== toggledField) {
        return toggledField;
      } else {
        return undefined;
      }
    });
  };

  return (
    <Container>
      <Header />
      <FirstPanel>
        <h1>Departments</h1>
        <ExpandableItem>
          <Label onClick={(e) => onToggle(e, "CPP")}>
            <SummaryText>
              <ExpandableIcon isExpanded={openDetails === "CPP"} />
              Clean Petroleum Products
            </SummaryText>
          </Label>
          <HiddenText visible={openDetails === "CPP"}>
            <div>
              <Link to="/contacts#cpp">+LR</Link>
            </div>
            <div>
              <Link to="/contacts#cpp">+MR</Link>
            </div>
            <div>
              <Link to="/contacts#coastal">+Coastal</Link>
            </div>
          </HiddenText>
        </ExpandableItem>
        <ExpandableItem>
          <Label onClick={(e) => onToggle(e, "DPP")}>
            <SummaryText>
              <ExpandableIcon isExpanded={openDetails === "DPP"} />
              Dirty Petroleum Products
            </SummaryText>
          </Label>
          <HiddenText visible={openDetails === "DPP"}>
            <div>
              <Link to="/contacts#dpp">+VLCC</Link>
            </div>
            <div>
              <Link to="/contacts#dpp">+Suezmax</Link>
            </div>
            <div>
              <Link to="/contacts#dpp">+Aframax</Link>
            </div>
            <div>
              <Link to="/contacts#dpp">+MR/Handy</Link>
            </div>
          </HiddenText>
        </ExpandableItem>
        <NonExpandableItem>
          <Link to="/contacts#gas">Gas</Link>
        </NonExpandableItem>
        <NonExpandableItem>
          <Link to="/contacts#operations">Operations</Link>
        </NonExpandableItem>
        <NonExpandableItem>
          <Link to="/contacts#claims">Claims</Link>
        </NonExpandableItem>
        <NonExpandableItem>
          <Link to="/contacts#accounts">Accounts</Link>
        </NonExpandableItem>
        <NonExpandableItem>
          <Link to="/contacts#administration">Administration</Link>
        </NonExpandableItem>
      </FirstPanel>
      <CallToAction text="Contact us for more information" />
      <Footer />
    </Container>
  );
};

export default Departments;
