import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import {
  Container,
  FirstRow,
  SecondRow,
  NavItem,
  Spacer,
  SocialContainer,
  CopyrightNotice,
} from "./styles/Footer.styled";
import Logo from "../static/icons/LogoBottom.svg";
// import LinkedInLogo from "../static/icons/LinkedIn.svg";
// import YoutubeLogo from "../static/icons/YouTube.svg";
// import TwitterLogo from "../static/icons/Twitter.svg";
// import FacebookLogo from "../static/icons/Facebook.svg";

const Footer: React.FC = () => {
  const year = new Date().getFullYear();

  const isMobile = useMediaQuery({
    query: "(max-width: 1100px)",
  });
  return (
    <Container>
      <FirstRow>
        <Link to="/">
          <img
            alt="Sentosa Shipbrokers logo"
            src={Logo}
            height={30}
            width={124}
            style={{
              paddingRight: !isMobile ? "30px" : undefined,
            }}
          />
        </Link>
        <>
          <Link to="/about" style={{ height: "100%" }}>
            <NavItem>About Us</NavItem>
          </Link>
          <Link to="/careers" style={{ height: "100%" }}>
            <NavItem>Careers</NavItem>
          </Link>
          <Link to="/find-us" style={{ height: "100%" }}>
            <NavItem>Find Us</NavItem>
          </Link>
          <Link to="/departments" style={{ height: "100%" }}>
            <NavItem>Departments</NavItem>
          </Link>
          <Link to="/contacts" style={{ height: "100%" }}>
            <NavItem>Contacts</NavItem>
          </Link>
          <Link to="/csr" style={{ height: "100%" }}>
            <NavItem>CSR</NavItem>
          </Link>
        </>
        <Spacer />
        <SocialContainer>
          {/*<img src={LinkedInLogo} alt="LinkedIn" />*/}
          {/*<img src={YoutubeLogo} alt="Youtube" />*/}
          {/*<img src={TwitterLogo} alt="Twitter" />*/}
          {/*<img src={FacebookLogo} alt="Facebook" />*/}
        </SocialContainer>
      </FirstRow>
      <SecondRow>
        {!isMobile ? (
          <>
            <span>© {year} Sentosa Ship Brokers Pte Ltd</span>
            <Spacer />
          </>
        ) : null}
        <span><Link to={"/privacy"}>Privacy</Link></span>
        <span><Link to={"/terms"}>Terms of Use</Link></span>
        <span><Link to={"/cookies"}>Cookies</Link></span>
      </SecondRow>
      {isMobile ? (
        <CopyrightNotice>© {year} Sentosa Ship Brokers Pte Ltd</CopyrightNotice>
      ) : null}
    </Container>
  );
};

export default Footer;
