import React from "react";
import { Button } from "./styles/IconButton.styled";

interface IconButtonProps {
  icon: string;
  onClick?: () => void;
}

const IconButton: React.FC<IconButtonProps> = ({ icon, onClick }) => {
  return (
    <Button onClick={onClick}>
      <img src={icon} alt="Icon" />
    </Button>
  );
};

export default IconButton;
