import styled from "styled-components";

export const Container = styled.div``;

export const FirstPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 400;
  font-size: 22px;
  background-color: #f7f7f7;
  padding: 0 124px;

  @media (max-width: 1200px) {
    padding: 0;
    margin-bottom: 10px;
  }

  > h1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 67px;
    margin-bottom: 60px;

    @media (max-width: 1200px) {
      margin-top: 40px;
      margin-bottom: 9px;
    }
  }
`;

export const Label = styled.span<{ bold?: boolean }>`
  cursor: pointer;
  font-size: 22px;
  font-weight: ${({ bold }) => (bold ? 900 : 400)};
`;

export const TableContainer = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  padding-bottom: 50px;

  @media (max-width: 1200px) {
    display: flex;
    width: 100%;
    padding-bottom: 0;
  }
`;

export const ContactsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: 40px;
  padding-left: 20px;
  padding-top: 10px;
  cursor: pointer;

  img {
    margin-right: 12px;
  }
`;

export const ContactInnerContainer = styled.div<{ isOpen?: boolean }>`
  border-top: ${({ isOpen }) => (isOpen ? "1px solid #979797" : "none")};
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 25px;
  img {
    margin-right: 16px;
  }
`;

export const TocItem = styled.div`
  margin: 40px 0;

  @media (max-width: 1200px) {
    padding-left: 25px;
    margin: 30px 0;
  }
`;

export const LeftPanel = styled.div`
  padding-top: 12px;
  border-right: 1px solid #979797;
`;

export const RightPanel = styled.div`
  width: 100%;
  padding-left: 40px;
`;

export const MobilePanel = styled.div`
  padding-left: 0;
  padding-top: 12px;
  width: 100%;
`;

export const Detail = styled.div`
  padding-left: 70px;
  padding-top: 20px;
  background-color: white;
  margin-left: 40px;
  padding-bottom: 20px;
`;

export const InnerDetail = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
`;

export const DetailMobile = styled.div<{ isOpen?: boolean }>`
  overflow: hidden;
  padding-top: 10px;
  background-color: white;
  padding-left: 40px;
  padding-bottom: 30px;
  border-bottom: ${({ isOpen }) => (isOpen ? "1px solid #979797" : "none")};
`;

export const InnerDetailMobile = styled.div`
  overflow: hidden;
  overflow-wrap: anywhere;
  padding-right: 10px;
`;

export const Contact = styled.div`
  margin: 30px 0;

  @media (max-width: 1200px) {
    margin: 0;
  }
`;

export const BackToDepartmentListButton = styled.button`
  background-color: transparent;
  padding: 10px 0;
  border: none;
  font-weight: 900;
  font-size: 22px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
  margin-left: 25px;
  color: #152941;

  svg {
    margin-right: 12px;
  }
`;

export const DepartmentHeader = styled.div`
  font-size: 22px;
  font-weight: 900;
  border-top: 1px solid #979797;
  border-bottom: 1px solid #979797;
  padding: 10px 0 10px 25px;
`;
