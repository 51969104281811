import styled from "styled-components";
import FindUsImage from "../../static/images/FindUs.jpg";
import DesktopMap from "../../static/images/Map.svg";

export const Container = styled.div``;

export const DesktopMapContainer = styled.div<{
  height: number;
  width: number;
}>`
  background-image: url(${DesktopMap}) !important;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: ${(props) => props.height + "px"};
  width: ${(props) => props.width + "px"};
  position: relative;
`;

export const PinImg = styled.img`
  height: 53px !important;
  object-fit: none !important;
  width: auto !important;
`;

export const DesktopMapPinContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  font-size: 22px;
  font-weight: 900;
  cursor: pointer;
  opacity: 0.6;

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  :hover {
    opacity: 1;
  }
`;

export const FirstPanel = styled.div`
  height: 830px;
  width: 100%;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  @media (max-width: 1200px) {
    height: 600px;
    background-color: white;

    img {
      height: 480px;
      width: 100%;
      object-fit: cover;
    }
  }

  h1 {
    margin-bottom: 80px;
    @media (max-width: 1200px) {
      margin-top: 40px;
      margin-bottom: 40px;
      font-size: 35px;
    }
  }
`;

export const OfficesContainer = styled.div`
  padding: 101px 128px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  color: white;
  width: calc(100% - 256px);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(${FindUsImage}) !important;

  @media (max-width: 1500px) {
    padding-left: 10px;
    padding-right: 10px;
    width: calc(100% - 20px);
  }

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    background-image: none !important;
    background-color: #1b363b;
    padding-left: 28px;
    width: calc(100% - 38px);
    padding-top: 10px;
    padding-bottom: 40px;
  }

  h3 {
    @media (max-width: 1200px) {
      font-size: 30px;
      margin-top: 30px;
    }
  }
`;

export const Address = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  margin-top: 20px;
  height: 140px;
`;

export const ContactHeader = styled.div`
  border-radius: 10px;
  opacity: 1;
  background-color: rgba(0, 97, 116, 1);
  font-size: 22px;
  color: white;
  font-weight: 400;
  height: 38px;
  width: 95px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 50px 0 50px 0;

  @media (max-width: 1200px) {
    margin-top: 60px;
    margin-bottom: 30px;
  }
`;

export const Label = styled.p`
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 10px;
`;

export const Contact = styled.p`
  font-size: 18px;
  font-weight: 700;
  margin: 0;
`;

export const ContactItem = styled.div`
  padding-left: 14px;
  margin: 40px 0;
`;
