import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: "Lato", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #152941;
  }

  a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
  }

  h1 {
    font-weight: 900;
    font-size: 60px;
    padding: 0;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 30px;
    }
  }

  h2 {
    font-size: 50px;
    font-weight: 900;
    padding: 0;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 35px;
    }
  }

  h3 {
    font-size: 30px;
    font-weight: 900;
    padding: 0;
    margin: 0;
    @media (max-width: 768px) {
      font-size: 25px;
    }
  }

  h4 {
    font-size: 22px;
    font-weight: 700;
    padding: 0;
    margin: 0;
    line-height: 30px;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
  }
`;

export default GlobalStyle;
