import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Container, FirstPanel} from "./styles/Terms.styled";

const Terms = () => {
  return (
    <Container>
      <Header />
      <FirstPanel>
        <h1>Cookie Policy</h1>
      </FirstPanel>
      <div style={{margin: "100px", marginTop: "20px"}}>
        <p><strong>Cookies - What Are They?</strong></p>

        <p>A "cookie" is a small text file that is stored on your computer, tablet or phone when you visit a website.
          Some cookies are deleted when you close down your browser.</p>

        <p>These are known as session cookies. Others remain on your device until they expire or you decide to delete
          them. These are known as persistent cookies and enable websites to remember things about you as a returning
          visitor.</p>

        <p>To find out more about cookies, including how to see what cookies have been set and how to manage and delete
          them, visit www.allaboutcookies.org. Alternatively, you can search the internet for other independent
          information on cookies.</p>

        <p><strong>Cookies - How We Use Them?</strong></p>

        <p>We may use cookies to:</p>

        <ul>
          <li>Recognise you as an individual user when you log on to the website;</li>
          <li>Track the number of times you visit particular pages and how often you see particular content or
            information.
          </li>
          <li>Authenticate your account, to allow you to access specific areas of our website</li>
        </ul>

        <p><strong>Cookies - Can I Opt Out?</strong></p>

        <p>Yes. You can set your browser to reject cookies or you can delete them yourself. Please note that the opt-out
          will only work for the computer and browser you are using when you opt-out. If you use more than one computer
          or browser you will have to opt-out on each.</p>

        <p>You may choose whether to accept, reject or be warned before accepting cookies by changing the settings on
          your browser. If you choose not to accept cookies you may find that certain parts of our website will not
          function properly or your user experience may be affected.</p>
      </div>
      <Footer/>
    </Container>
  );
};

export default Terms;
