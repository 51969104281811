import styled from "styled-components";

export const Container = styled.div``;

export const FirstPanel = styled.div`
  height: 200px;
  width: 100%;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  @media (max-width: 1200px) {
    height: 200px;
    background-color: white;
  }

  h1 {
    margin-bottom: 20px;
    @media (max-width: 1200px) {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 35px;
    }
  }
`;

