import styled from "styled-components";
import FirstPanelBackground from "../../static/images/Homepage1.jpg";
import FirstPanelMobileBackground from "../../static/images/mobile/Homepage1.jpg";
import AboutPanelBackground from "../../static/images/Homepage2.jpg";
import AboutPanelMobileBackground from "../../static/images/mobile/Homepage2.jpg";
import ServicesPanelBackground from "../../static/images/Homepage3.jpg";
import ServicesPanelMobileBackground from "../../static/images/mobile/Homepage3.jpg";

export const Container = styled.div``;

export const FirstPanel = styled.div`
  width: 100%;
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${FirstPanelBackground}) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding-bottom: 80px;

  @media (max-width: 768px) {
    background-image: url(${FirstPanelMobileBackground}) !important;
  }

  > h1 {
    margin: 500px 40px 0 40px;

    @media (max-width: 768px) {
      margin-top: 140px;
    }
  }

  > h4 {
    margin: 45px 40px;
  }
`;

export const AboutPanel = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const AboutLeftPanel = styled.div`
  display: flex;
  padding: 127px 127px;
  justify-content: center;
  align-items: center;

  h2 {
    margin-top: 20px;
    margin-bottom: 40px;
    @media (max-width: 768px) {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 768px) {
    padding: 50px 20px;
  }
`;

export const AboutRightPanel = styled.div`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${AboutPanelBackground}) !important;

  @media (max-width: 768px) {
    height: 600px;
    background-image: url(${AboutPanelMobileBackground}) !important;
  }

  h3 {
    margin: 10px 0;
    font-weight: 400;
  }

  h1 {
    font-weight: 400;
    font-size: 70px;
  }
`;

export const ServicesPanel = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: white;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(${ServicesPanelBackground}) !important;

  @media (max-width: 1300px) {
    display: flex;
  }

  @media (max-width: 1300px) {
    background-image: url(${ServicesPanelMobileBackground}) !important;
  }
`;

export const ServicesBottomContent = styled.div`
  margin-left: auto;
  margin-right: 20px;
`;

export const ServicesLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 127px;

  h2 {
    font-weight: 400;
  }
`;

export const ServicesRightContent = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 220px;
  padding-right: 124px;

  h2 {
    margin: 30px 0;
    font-weight: 700;
  }

  @media (max-width: 1300px) {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const LocationsPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 0 50px 0;

  > h2 {
    margin-bottom: 40px;

    @media (max-width: 768px) {
      margin-bottom: 0px;
    }
  }

  > img {
    margin-top: 20px;
    height: 522px;

    @media (max-width: 768px) {
      margin-top: 0px;
      height: 480px;
      width: 100%;
      object-fit: cover;
    }
  }
`;

export const ReadMoreContainer = styled.div`
  display: flex;
  margin-left: -8px;
`;
