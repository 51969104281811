import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CallToAction from "../components/CallToAction";
import {
  Container,
  FirstPanel,
  Company,
  CompanyContainer,
} from "./styles/CorporateAndSocialResponsibility.styled";
import MercyShips from "../static/images/MercyShips.png";
import Transfigura from "../static/images/Transfigura.png";
import MNDA from "../static/images/Mnda.png";

const CorporateAndSocialResponsibility = () => {
  return (
    <Container>
      <Header />
      <FirstPanel>
        <h2>Corporate and Social Responsibility</h2>
        <h4>Sentosa Ship Brokers are proud supporters of:</h4>
        <CompanyContainer>
          <Company>
            <div>
              <img height={80} width={98} src={MercyShips} alt="Mercy Ships" />
            </div>
            <p>mercyships.org.uk</p>
          </Company>
          <Company>
            <div>
              <img width={110} height={51} src={Transfigura} alt="Trafigura" />
            </div>
            <p>trafigurafoundation.org</p>
          </Company>
          <Company>
            <div>
              <img width={104} height={46} src={MNDA} alt="MNDA" />
            </div>
            <p>mndassociation.org</p>
          </Company>
        </CompanyContainer>
      </FirstPanel>
      <CallToAction text="Speak to one of our specialists" />
      <Footer />
    </Container>
  );
};

export default CorporateAndSocialResponsibility;
