import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FirstRow = styled.div`
  display: flex;
  height: 93px;
  align-items: center;
  width: calc(100% - 248px);
  justify-content: center;
  margin: 0 124px;

  @media (max-width: 1046px) {
    height: auto;
    width: auto;
    margin: 0;
    flex-direction: column;
  }

  img {
    @media (max-width: 1046px) {
      margin-bottom: 30px;
    }
  }
`;

export const SecondRow = styled.div`
  display: flex;
  color: #a1a1a1;
  padding-bottom: 49px;
  align-items: center;
  width: calc(100% - 248px);
  justify-content: center;
  margin: 0 124px;
  font-size: 14px;

  @media (max-width: 1046px) {
    padding-bottom: 0;
    margin: 0 20px;
    width: calc(100% - 40px);
  }

  > span {
    padding-right: 12px;

    @media (max-width: 1046px) {
      margin: 0 10px;
      padding: 0;
    }
  }
`;

export const NavItem = styled.div`
  cursor: pointer;
  height: 100%;
  padding: 0 14px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: #152941;

  @media (max-width: 1046px) {
    margin: 10px 0;
  }
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;

export const SocialContainer = styled.div`
  cursor: pointer;
  font-size: 16px;
  padding: 0 14px;
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 1046px) {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  img {
    @media (max-width: 1046px) {
      margin: 0 8px;
    }
  }
`;

export const CopyrightNotice = styled.span`
  color: #a1a1a1;
  margin-top: 25px;
  margin-bottom: 20px;
  font-size: 14px;
  width: 100%;
  text-align: center;
`;
