import styled from "styled-components";
import AboutPanelBackground from "../../static/images/AboutUs.jpg";
import AboutPanelMobileBackground from "../../static/images/mobile/About.jpg";

export const Container = styled.div``;

export const FirstPanel = styled.div`
  width: 100%;
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${AboutPanelBackground}) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding-bottom: 70px;

  @media (max-width: 768px) {
    background-image: url(${AboutPanelMobileBackground}) !important;
    padding-bottom: 110px;
  }

  > h1 {
    margin-top: 67px;

    @media (max-width: 768px) {
      margin-top: 110px;
    }
  }

  > h4 {
    margin: 45px 16%;

    @media (max-width: 768px) {
      margin-right: 20px;
      margin-left: 20px;
    }
  }
`;

export const AboutPanel = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;

  @media (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    height: auto;
  }
`;

export const AboutLeftPanel = styled.div`
  display: flex;
  padding: 78px 127px;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;

  @media (max-width: 1100px) {
    padding: 63px 22px;
  }

  h4 {
    font-weight: 900;
  }
`;

export const AboutRightPanel = styled.div`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #008099;

  @media (max-width: 1100px) {
    padding: 116px 0;
  }

  h3 {
    margin: 10px 0;
    font-weight: 400;
  }

  h1 {
    font-weight: 400;
    font-size: 70px;
  }
`;
