import React from "react";
import { useMediaQuery } from "react-responsive";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CallToAction from "../components/CallToAction";
import {
  Container,
  FirstPanel,
  OfficesContainer,
  Address,
  ContactHeader,
  Label,
  Contact,
  ContactItem,
  DesktopMapContainer,
  DesktopMapPinContainer,
  PinImg,
} from "./styles/FindUs.styled";
import PinImage from "../static/icons/Pin-Light.svg";
import DesktopMapPin from "../static/images/Pin.svg";
import LocationsMobileImage from "../static/images/mobile/MapMobile1x.png";

const Pin = () => {
  return (
    <img
      src={PinImage}
      alt="Location"
      style={{ marginRight: "12px" }}
      height={32}
    />
  );
};

const ClickableMap = () => {
  const useSmallVersion = useMediaQuery({
    query: "(max-width: 1200px)",
  });
  const onPinClicked = (id: string) => {
    const element = document.getElementById(id);
    element && element.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <DesktopMapContainer
      height={useSmallVersion ? 480 : 522}
      width={useSmallVersion ? 768 : 1071}
    >
      <DesktopMapPinContainer
        style={{ top: useSmallVersion ? "28%" : "26%", right: "47%" }}
        onClick={() => onPinClicked("geneva")}
      >
        <PinImg src={DesktopMapPin} alt="Geneva" />
        Geneva
      </DesktopMapPinContainer>
      <DesktopMapPinContainer
        style={{ top: "39%", right: "35.5%" }}
        onClick={() => onPinClicked("dubai")}
      >
        <PinImg src={DesktopMapPin} alt="Dubai" />
        <span>Dubai</span>
      </DesktopMapPinContainer>
      <DesktopMapPinContainer
        style={{ top: "38%", right: useSmallVersion ? "29%" : "30%" }}
        onClick={() => onPinClicked("india")}
      >
        <PinImg src={DesktopMapPin} alt="New Delhi" />
        New <br />
        Delhi
      </DesktopMapPinContainer>
      <DesktopMapPinContainer
        style={{
          top: useSmallVersion ? "49%" : "52%",
          right: useSmallVersion ? "20%" : "21%",
        }}
        onClick={() => onPinClicked("singapore")}
      >
        <PinImg src={DesktopMapPin} alt="Singapore" />
        Singapore
      </DesktopMapPinContainer>
    </DesktopMapContainer>
  );
};

const FindUs = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  return (
    <Container>
      <Header />
      <FirstPanel>
        <h1>Find Us</h1>
        {isMobile ? (
          <img src={LocationsMobileImage} alt="Locations" height={522} />
        ) : (
          <ClickableMap />
        )}
      </FirstPanel>
      <OfficesContainer>
        <div id="singapore">
          <h3>Singapore Office</h3>
          <Address>
            <Pin />
            25 Church Street, 03-03, <br />
            Capital Square, Singapore
          </Address>
          <ContactHeader>EMAIL</ContactHeader>
          <ContactItem>
            <Label>General email for CPP Desk</Label>
            <Contact>
              <a href="mailto:clean@sentosashipbrokers.com">
                clean@sentosashipbrokers.com
              </a>
            </Contact>
          </ContactItem>
          <ContactItem>
            <Label>General email for DPP Desk</Label>
            <Contact>
              <a href="mailto:dirty@sentosashipbrokers.com">
                dirty@sentosashipbrokers.com
              </a>
            </Contact>
          </ContactItem>
          <ContactItem>
            <Label>General email for Small Tankers Desk</Label>
            <Contact>
              <a href="mailto:small@sentosashipbrokers.com">
                small@sentosashipbrokers.com
              </a>
            </Contact>
          </ContactItem>
          <ContactItem>
            <Label>General email for Tanker Operations Desk</Label>
            <Contact>
              <a href="mailto:tankops@sentosashipbrokers.com">
                tankops@sentosashipbrokers.com
              </a>
            </Contact>
          </ContactItem>
          <ContactItem>
            <Label>General email for Claims Desk</Label>
            <Contact>
              <a href="mailto:claims@sentosashipbrokers.com">
                claims@sentosashipbrokers.com
              </a>
            </Contact>
          </ContactItem>
          <ContactHeader>PHONE</ContactHeader>
          <ContactItem>
            <Label>General number for Tanker Desk</Label>
            <Contact>
              <a href="tel:+65 6814 1888">+65 6814 1888</a>
            </Contact>
          </ContactItem>
          <ContactItem>
            <Label>General number for Tanker Operations Desk</Label>
            <Contact>
              <a href="tel:+65 6950 0800">+65 6950 0800</a>
            </Contact>
          </ContactItem>
        </div>

        <div id="dubai">
          <h3>Dubai Office</h3>
          <Address>
            <Pin />
            Office 6f, Gold Tower, Cluster I, <br />
            Jumeirah Lake Towers, Dubai, UAE
          </Address>
          <ContactHeader>EMAIL</ContactHeader>
          <ContactItem>
            <Label>General email for CPP Desk</Label>
            <Contact>
              <a href="mailto:clean@sentosashipbrokers.com">
                clean@sentosashipbrokers.com
              </a>
            </Contact>
          </ContactItem>
          <ContactItem>
            <Label>General email for DPP Desk</Label>
            <Contact>
              <a href="mailto:dirty@sentosashipbrokers.com">
                dirty@sentosashipbrokers.com
              </a>
            </Contact>
          </ContactItem>
        </div>

        <div id="geneva">
          <h3>Geneva Office</h3>
          <Address>
            <Pin />
            Place de Longemalle 14 1204 <br />
            Genève, Switzerland
          </Address>
          <ContactHeader>EMAIL</ContactHeader>
          <ContactItem>
            <Label>General email for CPP Desk</Label>
            <Contact>
              <a href="mailto:clean@sentosashipbrokers.com">
                clean@sentosashipbrokers.com
              </a>
            </Contact>
          </ContactItem>
          <ContactHeader>PHONE</ContactHeader>
          <ContactItem>
            <Label>General number</Label>
            <Contact>
              <a href="tel:+41 22 518 24 43">+41 22 518 24 43</a>
            </Contact>
          </ContactItem>
        </div>

        <div id="india">
          <h3>India Office</h3>
          <Address>
            <Pin />
            Sentosa Ship Brokers India Pvt. Ltd. <br />
            CoWrks, Ground Floor & First Floor, Unit No. 050 <br />
            World Mark 1, Asset Area 11 <br />
            Aerocity Hospitality District, Indira Gandhi International Airport <br />
            NH-8, New Delhi 110037<br />
          </Address>
          <ContactHeader>EMAIL</ContactHeader>
          <ContactItem>
            <Label>General email for CPP Desk</Label>
            <Contact>
              <a href="mailto:clean.india@sentosashipbrokers.com">
                clean.india@sentosashipbrokers.com
              </a>
            </Contact>
          </ContactItem>
          <ContactItem>
            <Label>General email for DPP Desk</Label>
            <Contact>
              <a href="mailto:dirty.india@sentosashipbrokers.com">
                dirty.india@sentosashipbrokers.com
              </a>
            </Contact>
          </ContactItem>
        </div>
      </OfficesContainer>
      <CallToAction text="Speak to one of our specialists" />
      <Footer />
    </Container>
  );
};

export default FindUs;
