import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Container, FirstPanel} from "./styles/Terms.styled";

const Terms = () => {
  return (
    <Container>
      <Header />
      <FirstPanel>
        <h1>Privacy Policy</h1>
      </FirstPanel>
      <div style={{margin: "100px", marginTop: "20px"}}>
        <p>The Sentosa Ship Brokers Pte Ltd ("We") are committed to protecting and respecting your privacy.</p>

        <p>This policy sets out the basis on which any personal data we collect from you, or that you provide to us in
          connection to the use of this website, will be processed by us. Please read the following carefully to
          understand our views and practices regarding your personal data and how we will treat it. By visiting
          sentosashipbrokers.com you are accepting and consenting to the practices described in this policy.</p>

        <p><strong>Information We May Collect From You</strong></p>

        <p>When you visit our website, we may collect and process the following:</p>

        <p>Information you provide if you contact us including company details, personal and sensitive data; Details of
          any transaction you carry out through our website; Details of visits made to our website including, but not
          limited to, the volume of traffic received, logs (including, where available, the IP address of the device
          connecting to the website) and the resources accessed; and If you apply online for a job you may need to
          provide information about your education, employment, region of interest and role you are applying for. Your
          application will constitute your express consent to our use of this information to assess your application and
          to allow us to carry out any monitoring activities, which may be required of us under applicable law as an
          employer.</p>

        <p><strong>Cookies</strong></p>

        <p>Our website uses cookies to distinguish you from other users of our website. This helps us to provide you
          with a good experience and also allows us to improve our site. For information on the cookies we use and the
          purposes for which we use them see our Cookie policy.</p>

        <p><strong>Uses Made of the Information</strong></p>

        <p>We use information held about you in a number of ways, including:</p>

        <p>To provide you with information and services that you request from us; To improve the content and methods of
          delivery of our website; To recognise you when you return to the website; To provide you with information or
          services that we feel may interest you, where you have consented to be contacted for such purposes; To allow
          you to participate in the interactive features of our service, if applicable, when you choose to do so; To
          comply with legal obligations; and To disclose your information, as set out below.</p>

        <p><strong>Disclosure of Your Information</strong></p>

        <p>We may share your personal information with any member of Sentosa Ship Brokers Pte Ltd, which means our
          subsidiaries, our ultimate holding company and its subsidiaries, as defined in section 1159 of the UK
          Companies Act 2006.</p>

        <p>We may share your information with selected third parties including analytics and search engine providers
          that assist us in the improvement and optimisation of our site.</p>

        <p>We may disclose your personal information to third parties: In the event that we sell or buy any business or
          assets, in which case we may disclose your personal data to the prospective seller or buyer of such business
          or assets; If all or substantially all of our assets are acquired by a third party, in which case personal
          data held by us will be one of the transferred assets; If we are under a duty to disclose or share your
          personal data in order to comply with any legal obligation; in order to enforce or apply our terms of use; or
          to protect the rights, property, or safety of Sentosa Ship Brokers Pte Ltd, our customers, or others; This
          includes exchanging information with other companies and organisations for the purposes of fraud protection
          and credit risk reduction.</p>

        <p><strong>Where We Store Your Personal Data</strong></p>

        <p>The data that we collect from you may be transferred to, and stored at, a destination outside the European
          Economic Area ("EEA"). It may also be processed by staff operating outside the EEA who work for us or for one
          of our suppliers. Such staff may be engaged in, among other things, the provision of support services or the
          processing of your application for employment. By submitting your personal data, you agree to this transfer,
          storing or processing. We will take all steps reasonably necessary to ensure that your data is treated
          securely and in accordance with this privacy policy.</p>

        <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we use
          reasonable efforts to protect your personal data, we cannot guarantee the security of your data transmitted to
          our site; any transmission is at your own risk. Once we have received your information, we will use strict
          procedures and security features to try to prevent unauthorised access.</p>

        <p><strong>Your Rights</strong></p>

        <p>You have the right to ask us not to process your personal data for marketing purposes. We will usually inform
          you (before collecting your data) if we intend to use your data for such purposes or if we intend to disclose
          your information to any third party for such purposes. You have the right to add, amend, delete or update your
          personal data at any time by contacting us at admin@sentosashipbrokers.com</p>

        <p>Our site may, from time to time, contain links to and from the websites of our partner networks, advertisers
          and affiliates. If you follow a link to any of these websites, please note that these websites have their own
          privacy policies and that we do not accept any responsibility or liability for these policies. Please check
          these policies before you submit any personal data to these websites.</p>

        <p><strong>Access to Information</strong></p>

        <p>The privacy laws of some countries give individuals the right to access and amend their personal information.
          If you would like to request a copy of your information or would like to change the information we hold about
          you, please contact us at admin@sentosashipbrokers.com</p>

        <p><strong>Changes to Our Privacy Policy</strong></p>

        <p>Any changes we may make to our privacy policy in the future will be posted on this page. Please check back
          frequently to see any updates or changes to our privacy policy.</p>

        <p><strong>Contact</strong></p>

        <p>Questions, comments and requests regarding this privacy policy should be addressed to
          admin@sentosashipbrokers.com</p>
      </div>
      <Footer/>
    </Container>
  );
};

export default Terms;
