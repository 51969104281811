import React from "react";
import styled from "styled-components";
import GetInTouchButton from "./GetInTouchButton";

const CallToActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 120px 0;

  @media (max-width: 768px) {
    padding: 40px 16px 60px 16px;
    text-align: center;
  }

  > h2 {
    margin-bottom: 40px;
  }
`;

interface CallToActionProps {
  text: string;
}

const CallToAction: React.FC<CallToActionProps> = ({ text }) => {
  return (
    <CallToActionContainer>
      <h2>{text}</h2>
      <GetInTouchButton />
    </CallToActionContainer>
  );
};

export default CallToAction;
